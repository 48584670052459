import { useQuery } from "@tanstack/react-query";
import { flatten, path, uniqBy } from "ramda";
import BigNumber from "bignumber.js";
import { AccAddress, ValAddress, Validator } from "@terra-money/terra.js";
import { Delegation, UnbondingDelegation } from "@terra-money/terra.js";
/* FIXME(terra.js): Import from terra.js */
import { BondStatus } from "@terra-money/terra.proto/cosmos/staking/v1beta1/staking";
import { has } from "utils/num";
import { StakeAction } from "txs/stake/StakeForm";
import { queryKey, Pagination, RefetchOptions } from "../query";
import { useAddress } from "../wallet";
import { useLCDClient } from "./lcdClient";

export const useValidators = () => {
	const lcd = useLCDClient();

	return useQuery({
		queryKey: [queryKey.staking.validators],
		queryFn: async () => {
			// TODO: Pagination
			// Required when the number of results exceed LAZY_LIMIT

			const [v1] = await lcd.staking.validators({
				status: BondStatus[BondStatus.BOND_STATUS_UNBONDED],
				...Pagination,
			});

			const [v2] = await lcd.staking.validators({
				status: BondStatus[BondStatus.BOND_STATUS_UNBONDING],
				...Pagination,
			});

			const [v3] = await lcd.staking.validators({
				status: BondStatus[BondStatus.BOND_STATUS_BONDED],
				...Pagination,
			});

			return uniqBy(path(["operator_address"]), [...v1, ...v2, ...v3]);
		},
		...RefetchOptions.INFINITY,
	});
};

export const useValidator = (operatorAddress: ValAddress) => {
	const lcd = useLCDClient();
	return useQuery({
		queryKey: [queryKey.staking.validator, operatorAddress],
		queryFn: () => lcd.staking.validator(operatorAddress),
		...RefetchOptions.INFINITY,
	});
};

export const useDelegations = () => {
	const address = useAddress();
	const lcd = useLCDClient();

	return useQuery({
		queryKey: [queryKey.staking.delegations, address],
		queryFn: async () => {
			if (!address) return [];
			// TODO: Pagination
			// Required when the number of results exceed LAZY_LIMIT
			const [delegations] = await lcd.staking.delegations(address, undefined, Pagination);

			return delegations.filter(({ balance }) => has(balance.amount.toString()));
		},
		...RefetchOptions.DEFAULT,
	});
};

export const useDelegation = (validatorAddress: ValAddress) => {
	const address = useAddress();
	const lcd = useLCDClient();

	return useQuery({
		queryKey: [queryKey.staking.delegation, address, validatorAddress],
		queryFn: async () => {
			if (!address) return;
			try {
				const delegation = await lcd.staking.delegation(address, validatorAddress);
				return delegation;
			} catch (error) {
				// TODO: it should return with responses message instead of use-query default
				if (error.response.data.message && error.response.data.message.includes("code = NotFound")) {
					return Promise.reject(new Error(error.response.data.message));
				}
				return;
			}
		},
		...RefetchOptions.DEFAULT,
	});
};

export const useUnbondings = () => {
	const address = useAddress();
	const lcd = useLCDClient();

	return useQuery({
		queryKey: [queryKey.staking.unbondings, address],
		queryFn: async () => {
			if (!address) return [];
			// Pagination is not required because it is already limited
			const [unbondings] = await lcd.staking.unbondingDelegations(address);
			return unbondings;
		},
		...RefetchOptions.DEFAULT,
	});
};

export const useStakingPool = () => {
	const lcd = useLCDClient();
	return useQuery({
		queryKey: [queryKey.staking.pool],
		queryFn: () => lcd.staking.pool(),
		...RefetchOptions.INFINITY,
	});
};

/* helpers */
export const getFindValidator = (validators: Validator[]) => {
	return (address: AccAddress) => {
		const validator = validators.find((v) => v.operator_address === address);
		if (!validator) throw new Error(`${address} is not a validator`);
		return validator;
	};
};

export const getFindMoniker = (validators: Validator[]) => {
	return (address: AccAddress) => {
		const validator = getFindValidator(validators)(address);
		return validator.description.moniker;
	};
};

export const getAvailableStakeActions = (destination: ValAddress, delegations: Delegation[]) => {
	return {
		[StakeAction.DELEGATE]: true,
		[StakeAction.REDELEGATE]:
			delegations.filter(({ validator_address }) => validator_address !== destination)
				.length > 0,
		[StakeAction.UNBOND]: !!delegations.filter(
			({ validator_address }) => validator_address === destination
		).length,
	};
};

/* delegation */
export const calcDelegationsTotal = (delegations: Delegation[]) => {
	try {
		return delegations.length
			? BigNumber.sum(
					...delegations.map(({ balance }) => balance.amount.toString())
				).toString()
			: "0";
	} catch (error) {
		return "0";
	}
};

/* unbonding */
export const calcUnbondingsTotal = (unbondings: UnbondingDelegation[]) => {
	return BigNumber.sum(...unbondings.map(({ entries }) => sumEntries(entries))).toString();
};

export const flattenUnbondings = (unbondings: UnbondingDelegation[]) => {
	return flatten(
		unbondings.map(({ validator_address, entries }) => {
			return entries.map((entry) => ({ ...entry, validator_address }));
		})
	).sort((a, b) => a.completion_time.getTime() - b.completion_time.getTime());
};

export const sumEntries = (entries: UnbondingDelegation.Entry[]) =>
	BigNumber.sum(...entries.map(({ initial_balance }) => initial_balance.toString())).toString();
